<template>
  <div id="history">
    <Navbar class="white-text white-logo light-menu-icon" />

    <section>
      <h1>History of HFA</h1>
      <h2>A legacy of trust. A commitment to innovation.</h2>
      <p>
        The Harry Fox Agency (HFA), established in 1927, America’s premiere
        mechanical licensing institution and has led the industry with
        innovative mechanical rights management solutions for music creators and
        publishers. Our mission is to provide to our partners timely, efficient
        mechanical rights management and royalty distribution services while
        meeting the changing mechanical needs of music distributors, songwriters
        and publishers.
      </p>
      <p>
        Today, as the gold-standard in rights management, HFA represents nearly
        50,000 affiliated publishers and licenses more than 2,500 record labels.
        With its continued growth, HFA remains home to the world’s largest, most
        authoritative database of musical works linked to recordings. In
        addition, our Songfile® service supports schools, religious
        institutions, indie artists and record labels who need to license cover
        songs.
      </p>
      <p>
        Rumblefish has also established itself over the past decade as a leader
        in administration services. Rumblefish remains the most trusted and
        reliable provider of administration services and royalty administration
        for over fifty media and technology clients.
      </p>
      <p>
        Since the acquisition by SESAC in 2015, significant investments have
        been made in HFA’s data services and technology infrastructure as well
        as a wide variety of cloud-based applications to run our client services
        platform. We will continue to invest heavily in our platforms and
        resources as we evolve our streamlined, global music licensing and
        royalty generation network.
      </p>
      <p>
        As part of our company’s global plan, in 2017 we launched Mint Digital
        Services, a joint venture between SESAC and Swiss collection society,
        SUISA. It was the first transatlantic collaboration between
        organizations that represent musical works on behalf of publishers and
        songwriters and is the only company to span both U.S. and European
        copyright laws. Today, Mint is providing services to major publishers
        such as BMG, along with a number of independent publishers through the
        HFA Affiliate Program.
      </p>
      <p>
        It’s a new licensing frontier and HFA continues to provide the highest
        level of service to the industry. After the passage of the Music
        Modernization Act in October 2018, The Mechanical Licensing Collective
        (The MLC) was created to administer blanket mechanical licenses for
        eligible online streaming services and collect digital audio royalties
        on the publishers’ behalf. As a vendor to The MLC, HFA shares its
        musical works data and assists with matching and royalty processing
        efforts.
      </p>
      <p>
        As we head into the future, HFA will continue to provide new licensing
        opportunities to the publishing community and the music industry as a
        whole in interesting ways outside of the traditional mechanical
        licensing arena. Furthermore, music publishers still have the
        opportunity to grow and earn more through HFA’s network of international
        society agreements, plus access to Mint’s negotiated worldwide
        agreements at lower administration costs.
      </p>
      <p>
        HFA’s commitment to works matching, licensing, royalty collection and
        distribution will not waiver, and we invite anyone creating music to
        visit harryfox.com and to sign up as an affiliate.
      </p>

      <img
        src="../assets/history-graphs.png"
        class="graphs"
        alt="background image"
      />

      <div class="background">
        <img src="../assets/background-01.svg" alt="background image" />
      </div>
    </section>

    <div class="clear"></div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "History",
  components: {
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - History",
      meta: [
        {
          name: "description",
          content: "A legacy of trust. A commitment to innovation."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#history {
  background-color: $blue;
  width: calc(100vw - 8px);
  margin: -8px 0 0 -8px;
  padding: 8px;

  .clear {
    clear: both;
  }

  #navbar .mobile-menu .menu {
    width: 103vw;
    height: 104vh;
  }

  section {
    background-color: $blue;

    a {
      text-decoration: underline;
      color: #fff;
    }
    img {
      z-index: -1;
    }
    h1,
    h2,
    p {
      color: #fff;
    }
    h1 {
      font-size: 1.1em;
      margin-bottom: 0;
      margin-top: 40px;
    }
    h2 {
      font-size: 1.375em;
      font-family: "Fjalla One", sans-serif;
      margin-bottom: 20px;
    }
    p {
      font-size: 1em;
      line-height: 2.26;
    }
    .graphs {
      width: 90vw;
      display: block;
      margin: 10px auto 100px;
      z-index: 1;
    }
    > .background {
      display: none;

      .more-background {
        background-color: $blue;
        height: 185vh;
      }
    }
  }

  #footer {
    margin-left: 8px;
  }

  @media (min-width: $desktop) {
    background-color: transparent;
    margin: 0;
    padding: 0;
    width: auto;

    #footer {
      margin-left: 0;
      bottom: -269px;
    }

    section {
      height: auto;
      padding: 61px 0 100px 50px;
      margin: -61px 0 400px -50px;

      > .background {
        display: block;
        position: absolute;
        z-index: -2;
        left: 0;
        width: 100%;
        overflow: hidden;
        transform: translateY(-55vw);

        img {
          position: relative;
          width: 123vw;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      img {
        z-index: -1;
      }
      h1 {
        font-size: 1.75em;
        margin-bottom: 40px;
        margin-top: 50px;
      }
      h2 {
        font-size: 1.375em;
        margin-bottom: 40px;
      }
      p {
        font-size: 1.1875em;
        line-height: 2.26;
        margin-right: 7.028vw;
      }
      .graphs {
        width: 47.511vw;
        width: 650px;
        margin: 0 7.028vw 0 0;
        float: right;
      }
    }
  }
}
</style>
